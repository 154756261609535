import request from '@/utils/request'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    authorizationCodeCallback(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/agent/add',
          method: 'post',
          data: data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getBarCodeImage(ctx, params) {
      return new Promise((resolve, reject) => {
        request({
          url: '/order/getBarCodeImage',
          method: 'get',
          params: params,
          responseType: 'blob',
          emulateJSON: true
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getFileUrl() {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/getRandCodeImage',
          method: 'get',
          responseType: 'blob',
          emulateJSON: true
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPhoneCode(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/getPhoneCode',
          method: 'post',
          data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    authenticate(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/authenticate',
          method: 'post',
          data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    validate(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/company/validate',
          method: 'post',
          data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    worksite(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/worksite/list',
          method: 'get',
          params: data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setWorksite(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/worksite/set',
          method: 'post',
          data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    legalAuth(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/legal/auth',
          method: 'post',
          data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    applySubmit(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/submit',
          method: 'post',
          data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
