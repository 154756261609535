import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import main from '@/store/modules/main'
import apply from '@/store/modules/apply'
import step from '@/store/modules/step'
import uploads from '@/store/modules/uploads'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { user, main, apply, step, uploads }
})
