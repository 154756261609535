import axios from 'axios'
import { USER_TOKEN } from '@/utils/const'
import { Dialog } from 'vant'
import router from '@/router'
import store from '@/store'

import jscookie from 'js-cookie'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/cas' : '/dev-api',
  // 超时
  timeout: 60 * 1000
})
// request拦截
service.interceptors.request.use(
  async (config) => {
    let Authorization = jscookie.get(USER_TOKEN) ? 'Bearer ' + jscookie.get(USER_TOKEN) : ''
    if (config.url.indexOf('/token/wx') == -1) {
      config.headers['Authorization'] = Authorization
    }
    return config
  },
  (error) => {
    Promise.resolve(error)
  }
)
// 拦截响应
service.interceptors.response.use(
  async (res) => {
    if (res.status !== 200) {
      return Promise.reject(new Error(res.data.message || '服务器异常'))
    } else {
      return res.data
    }
  },
  (error) => {
    console.log('error', JSON.stringify(error))
    let status = error?.response?.status
    if (status === 401) {
      error.message = '授权登录失效'
      Dialog({
        message: '授权登录失效',
        confirmButtonText: '重新授权'
      }).then(() => {
        store.commit('SET_TOKEN', '')
        router.replace({
          path: '/login',
          query: {
            ...router.history.current.query,
            ...{ redirect: router.history.current.path }
          }
        })
      })
    }
    let { message } = error
    if (message == 'Network Error') {
      error.message = '网络异常，请稍后再试'
    } else if (message.includes('timeout')) {
      error.message = '网络繁忙，请稍后再试'
    } else if (message.includes('Request failed with status code 500')) {
      error.message = '服务器异常，请稍后再试'
    }
    return Promise.reject(error?.response || error)
  }
)
export default service
