import request from '@/utils/request'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    uploadImage(ctx, { data, query }) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form_file/upload',
          method: 'post',
          data: data,
          query: query,
          params: query,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getImageFileUrl(ctx, params) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/getFileUrl',
          method: 'get',
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteFile(ctx, data) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/deleteFile',
          method: 'post',
          data: data
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
