import request from '@/utils/request'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getProjectList() {
      return new Promise((resolve, reject) => {
        request({
          url: '/project/list',
          method: 'get'
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getProjectGoodsInfo(ctx, appId) {
      return new Promise((resolve, reject) => {
        request({
          url: '/project/goods/info',
          method: 'get',
          params: { appId }
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getApplicationFormList(ctx, appId) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/list',
          method: 'get',
          params: { appId }
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
