import Vue from 'vue'
import VueRouter from 'vue-router'
import jscookie from 'js-cookie'
import { USER_TOKEN } from '@/utils/const'

// const Index = () => import(/* webpackChunkName: 'index' */ '@/views/index/index.vue')
const Protocol = () => import(/* webpackChunkName: 'protocol' */ '@/views/protocol/index.vue')
const ApplyList = () => import(/* webpackChunkName: 'apply-list' */ '@/views/apply-list/apply-list.vue')
const Step = () => import(/* webpackChunkName: 'step' */ '@/views/step/index.vue')
const AgentInfo = () => import(/* webpackChunkName: 'agent-info' */ '@/views/agent-info/index.vue')
const CompanyInfo = () => import(/* webpackChunkName: 'company-info' */ '@/views/company-info/index.vue')
const PapersUpload = () => import(/* webpackChunkName: 'papers-upload' */ '@/views/papers-upload/index.vue')
const RecevingType = () => import(/* webpackChunkName: 'receving-type' */ '@/views/receving-type/index.vue')
const OrderSure = () => import(/* webpackChunkName: 'order-sure' */ '@/views/order-sure/index.vue')
const OrderInfo = () => import(/* webpackChunkName: 'order-info' */ '@/views/order-info/index.vue')
const Pay = () => import(/* webpackChunkName: 'pay' */ '@/views/pay/index.vue')
const BlankInfo = () => import(/* webpackChunkName: 'blank-info' */ '@/views/blank-info/index.vue')
const MyApply = () => import(/* webpackChunkName: 'my-apply' */ '@/views/my-apply/index.vue')
const Login = () => import(/* webpackChunkName: 'login' */ '@/views/login/login.vue')
const Help = () => import(/* webpackChunkName: "help" */ '@/views/help/help.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Protocol,
    meta: {
      requireAuth: false // 需要登录访问
    }
  },
  {
    path: '/index',
    name: 'index',
    component: Protocol,
    meta: {
      requireAuth: false // 需要登录访问
    }
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: Protocol,
    meta: {
      requireAuth: false // 需要登录访问
    }
  },
  {
    path: '/applyList',
    name: 'applyList',
    component: ApplyList,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  {
    path: '/myApply',
    name: 'myApply',
    component: MyApply,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  {
    path: '/step',
    name: 'step',
    component: Step,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  // 经办人信息
  {
    path: '/agentInfo',
    name: 'agentInfo',
    component: AgentInfo,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  // 单位信息
  {
    path: '/companyInfo',
    name: 'companyInfo',
    component: CompanyInfo,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  // 照片信息上传
  {
    path: '/papersUpload',
    name: 'papersUpload',
    component: PapersUpload,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  // 照片信息上传
  {
    path: '/recevingType',
    name: 'recevingType',
    component: RecevingType,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  // 照片信息上传
  {
    path: '/orderSure',
    name: 'orderSure',
    component: OrderSure,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  // 照片信息上传
  {
    path: '/orderInfo',
    name: 'orderInfo',
    component: OrderInfo,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: Pay,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  {
    path: '/blankInfo',
    name: 'blankInfo',
    component: BlankInfo,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
    meta: {
      requireAuth: true // 需要登录访问
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requireAuth: false // 需要登录访问
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/zssq/' : '',
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '上海CA中心'
  }
  const token = jscookie.get(USER_TOKEN)
  const isLogin = token != '' && typeof token !== 'undefined'
  if (to.matched.some((record) => record.meta.requireAuth) && !isLogin) {
    console.log('去登录')
    next({
      name: 'login',
      query: {
        redirect: to.name,
        ...to.query,
        ...to.params
      }
    })
  } else {
    next()
  }
})
export default router
