import request from '@/utils/request'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getApplicationFormAgentList() {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/agent/list',
          method: 'get'
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getApplicationFormInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        request({
          url: '/application_form/info',
          method: 'get',
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
