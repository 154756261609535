import request from '@/utils/request'
import { USER_TOKEN } from '@/utils/const'
import jscookie from 'js-cookie'

export default {
  // namespaced: true,
  state: {
    // 微信还没有接入，先使用openId ‘oVBZ4xCaorIUt2-R2h8o-vnof4OA’
    openId: 'oVBZ4xCaorIUt2-R2h8o-vnof4OA',
    token: '',
    jsSdkInfo: {}
  },
  getters: {
    token: (state) => {
      return state.token
    }
  },
  mutations: {
    SET_SDK_INFO(state, jsSdkInfo) {
      state.jsSdkInfo = jsSdkInfo
    },
    SET_TOKEN(state, token) {
      state.token = token
      jscookie.set(USER_TOKEN, token)
    }
  },
  actions: {
    updateWxJsSdkInfo(ctx, data) {
      return new Promise((resovle, reject) => {
        request({
          url: '/wechat/getJsTicket',
          method: 'get',
          data
        })
          .then((data) => {
            ctx.commit('SET_SDK_INFO', data)
            resovle(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getWxToken(ctx, code) {
      return new Promise((resovle, reject) => {
        request({
          url: '/token/wxToken',
          method: 'get',
          params: { code }
        })
          .then((data) => {
            if (Object.prototype.toString.call(data) === '[object String]') {
              if (data.indexOf('关注公众号') > -1) {
                ctx.commit('SET_TOKEN', '')
              } else {
                ctx.commit('SET_TOKEN', data)
              }
            }
            resovle(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getTokenWx(ctx, params) {
      return new Promise((resolve, reject) => {
        request({
          url: '/token/wx',
          method: 'post',
          data: params
        })
          .then((data) => {
            ctx.commit('SET_TOKEN', data)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
